<template>
    <div class="customManageEdit">
        <div class="main-flex">
            <edit-btns class="btn-box" 
                :bnts="bnts"
                @saveClick="saveData()"
                @goBackClick="back()"/>
        </div>
        <div class="panel-box">
            <div class="panel-heading">主信息</div>
            <div class="panel-body">
                <el-row :gutter="10">
                    <el-col :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
                        <div class="flex-box">
                            <span class="lable-box"><span class="red">*</span>姓名：</span>
                            <div  class="input-from"><input type="text" v-model="info.Cstm_Name"/></div>
                        </div>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
                        <div class="flex-box">
                            <span class="lable-box">性别：</span>
                            <div  class="input-from">
                                <el-select v-model="info.Cstm_Sex">
                                    <el-option label="女" :value="false"></el-option>
                                    <el-option label="男" :value="true"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
                        <div class="flex-box">
                            <span class="lable-box"><span class="red">*</span>手机号：</span>
                            <div  class="input-from"><input type="text" v-model="info.Cstm_Mobile"/></div>
                        </div>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
                        <div class="flex-box">
                            <span class="lable-box">其他号码：</span>
                            <div  class="input-from"><input type="text" v-model="info.Cstm_OrtherMobile"/></div>
                        </div>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
                        <div class="flex-box">
                            <span class="lable-box">生日类型：</span>
                            <div  class="input-from">
                                <el-radio-group v-model="info.Cstm_BirthdayBit" class="radio-group">
                                    <el-radio :label="false">公历</el-radio>
                                    <el-radio :label="true">农历</el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
                        <div class="flex-box">
                            <el-checkbox label="是否忽略年份" v-model="info.Lose_BirthdayYear"/>
                        </div>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
                        <div class="flex-box">
                            <span class="lable-box">公历生日：</span>
                            <div  class="input-from">
                                <el-date-picker class="from-date"
                                    :clearable="false"
                                    :editable="false"
                                    v-model="info.Cstm_Birthday"
                                    type="date"
                                    placeholder=""
                                    :default-value="new Date()">
                                </el-date-picker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
                        <div class="flex-box">
                            <span class="lable-box">农历生日：</span>
                            <div  class="input-from">
                                <lunar-calendar 
                                    v-model="info.Cstm_Birthday"
                                    type="date"
                                    placeholder="" v-slot="{lunarDate}" @change="(date,lunarDate)=>{info.Cstm_LunarBirthday=(lunarDate.lunarYear+'('+lunarDate.year+')')+lunarDate.dateStr}">
                                    {{lunarDate.lunarYear}}({{lunarDate.year}}){{lunarDate.dateStr}}
                                </lunar-calendar>
                            </div>
                        </div>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
                        <div class="flex-box">
                            <span class="lable-box">客户级别：</span>
                            <div  class="input-from">
                                <el-select v-model="info.Cstm_LevelID">
                                    <el-option v-for="item in cstmLevelList"
                                        :key="item.Autoid"
                                        :label="item.LevelName"
                                        :value="item.Autoid"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
                        <div class="flex-box">
                            <span class="lable-box">客户渠道：</span>
                            <div class="input-from input-icon" @click="orderChanneShow=true">
                                <input type="text" v-model="info.Channel_Name" placeholder="请选择" readonly/> <i class="iconfont icon-qudao"></i>
                            </div>
                        </div>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
                        <div class="flex-box">
                            <span class="lable-box">营销员：</span>
                            <div class="input-from input-icon" @click="waiSaleShow=true">
                                <input type="text" v-model="info.Eat_Sales" placeholder="请选择" readonly/> <i class="iconfont icon-kehu" ></i>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="tr-box">
                <div class="lable-txt">客户忌口：</div>
                <div class="flex-box">
                    <div class="from-input"><input type="text" v-model="dietingTag" placeholder="可输入内容"/></div>
                    <div class="bnt bnt-tal" @click="addLable(2)">
                        <i class="iconfont icon-plus-circle"></i> 添加标签
                    </div>
                </div>
                <div class="tag-box">
                    <span class="tag" v-for="(item,index) in dietingLableList" :key="index">{{item.LableName}} <i @click="removeLable(2,index)" class="iconfont icon-guanbi"></i></span>
                    <!-- <span class="tag-more">更多<i class="iconfont icon-a-order-down"></i></span> -->
                </div>
            </div>
            <div class="tr-box">
                <div class="lable-txt">客户喜好：</div>
                <div class="flex-box">
                    <div class="from-input"><input type="text" v-model="likeTag" placeholder="可输入内容"/></div>
                    <div class="bnt bnt-tal" @click="addLable(1)">
                        <i class="iconfont icon-plus-circle"></i> 添加标签
                    </div>
                </div>
                <div class="tag-box">
                    <span class="tag" v-for="(item,index) in likeLableList" :key="index">{{item.LableName}} <i @click="removeLable(1,index)" class="iconfont icon-guanbi"></i></span>
                    <!-- <span class="tag-more">更多<i class="iconfont icon-a-order-down"></i></span> -->
                </div>
            </div>
            <div class="tr-box">
                <div class="lable-txt">自定义标签：</div>
                <div class="flex-box">
                    <div class="from-input"><input type="text" v-model="divTag" placeholder="可输入内容"/></div>
                    <div class="bnt bnt-tal" @click="addLable(0)">
                        <i class="iconfont icon-plus-circle"></i> 添加标签
                    </div>
                </div>
                <div class="tag-box">
                    <span class="tag" v-for="(item,index) in diyLableList" :key="index">{{item.LableName}} <i @click="removeLable(0,index)" class="iconfont icon-guanbi"></i></span>
                    <!-- <span class="tag-more">更多<i class="iconfont icon-a-order-down"></i></span> -->
                </div>
            </div>
        </div>
        <div class="panel-box">
            <div class="panel-heading">纪念日</div>
            <div class="panel-body">
                <div class="li-box" v-for="(item,index) in info.CstmInfoComDayList" :key="index">
                    <div class="delete" @click="deleteComDay(index)"><i class="iconfont icon-shanchu"></i></div>
                    <el-select v-model="item.RelationName">
                        <el-option v-for="kind in relationList" :key="kind"
                            :label="kind.KINDNAME" :value="kind.KINDNAME"></el-option>
                    </el-select>
                    <el-select v-model="item.RelationType">
                        <el-option v-for="kind in comDayList" :key="kind"
                            :label="kind.KINDNAME" :value="kind.KINDNAME"></el-option>
                    </el-select>
                    <lunar-calendar 
                        v-model="item.ComDay"
                        type="date"
                        placeholder="" v-slot="{lunarDate}" @change="(date,lunarDate)=>{item.LunarComDay=(lunarDate.lunarYear+'('+lunarDate.year+')')+lunarDate.dateStr}">
                        <template v-if="item.ComDayType">
                            {{ (item.Lose_ComYear?"":lunarDate.lunarYear+"("+lunarDate.year+")")+lunarDate.dateStr}}
                        </template>
                        <template v-else>
                            {{ item.Lose_ComYear?new Date(item.ComDay).Format("MM-dd"): new Date(item.ComDay).Format("yyyy-MM-dd")}}
                        </template>
                    </lunar-calendar>

                    <el-checkbox label="农历" v-model="item.ComDayType"/>
                    <el-checkbox label="是否忽略年份" v-model="item.Lose_ComYear"/>
                </div>
                <div class="add-btn" @click="addComDay()"><i class="iconfont icon-plus-circle"></i>添加纪念日</div>
            </div>
        </div>
        <div class="panel-box">
            <div class="panel-heading">其它信息</div>
            <div class="panel-body">
                <el-row :gutter="10">
                    <el-col :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
                        <div class="flex-box">
                            <span class="lable-box">身份证号：</span>
                            <div  class="input-from"><input type="text" v-model="info.IDCardNO"/></div>
                        </div>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
                        <div class="flex-box">
                            <span class="lable-box">单位名称：</span>
                            <div  class="input-from"><input type="text" v-model="info.Cstm_Company"/></div>
                        </div>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
                        <div class="flex-box">
                            <span class="lable-box">地址：</span>
                            <div  class="input-from"><input type="text" v-model="info.Cstm_Addr"/></div>
                        </div>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
                        <div class="flex-box">
                            <span class="lable-box">备注：</span>
                            <div  class="input-from"><input type="text" v-model="info.REMARK"/></div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <!--营销员-->
        <modal-load :isShow="waiSaleShow">
            <waiter-Sales-Model title="营销员" :isShow="waiSaleShow" @closeModel="waiSaleShow=false" :SalesList="salesList" :waiSaleType="2" @waiSaleConfirm="waiSaleConfirm" />
        </modal-load>
        <!--订单渠道-->
        <modal-load :isShow="orderChanneShow">
            <order-Channel-Model title="客户渠道" :isShow="orderChanneShow" @closeModel="orderChanneShow=false" 
                :Openorderclass="channel_AutoID" :OrderList="channelList" :orderChannel="channelNav" 
                @orderChanneConfirm="orderChanneConfirm" />
        </modal-load>
    </div>
</template>

<script>

import waiterSalesModel from '../../../diningTable/model/waiterSalesModel/waiterSalesModel.vue'
import orderChannelModel from '../../../diningTable/model/orderChannelModel/orderChannelModel.vue'
export default {
    components:{
        waiterSalesModel,
        orderChannelModel,
    },
    name:'editPrintModuleSet',
    data(){
        return {
            //客户级别
            cstmLevelList:[],
            //纪念日类型
            comDayList:[],
            //关系类型
            relationList:[],
            //营销员 弹层
            waiSaleShow:false,
            //营销员数据
            salesList:[],
            //订单渠道 弹层
            orderChanneShow:false,
            //渠道数据
            channelList:[],
            //渠道分类
            channelNav:[],
            
            info:{
                Cstm_Name:'',
                //性别
                Cstm_Sex:true,
                //手机号
                Cstm_Mobile:'',
                //其他号码
                Cstm_OrtherMobile:'',
                //生日类型 false 公立 true 农历
                Cstm_BirthdayBit:false,
                //是否忽略年份
                Lose_BirthdayYear:false,
                //公历生日
                Cstm_Birthday:new Date(),
                //农历生日
                Cstm_LunarBirthday:new Date(),
                //级别
                Cstm_LevelID:"",
                //客户级别名称
                Cstm_LevelName:"",
                //渠道
                Channel_Name:"",
                //渠道 id
                Channel_ID:"",
                //营销员
                Eat_Sales:"",
                //营销员 id
                Eat_SalesID:"",
                //单位名称
                Cstm_Company:"",
                //联系地址
                Cstm_Addr:"",
                //身份证号
                IDCardNO:"",
                //备注
                REMARK:"",
                //纪念日
                CstmInfoComDayList:[],
                //标签
                CstmInfoLabelList:[]
            },
            //自定义
            divTag:"",
            //忌口
            dietingTag:"",
            //喜好
            likeTag:"",
            //忌口
            dietingLableList:[],
            //喜好
            likeLableList:[],
            //自定义
            diyLableList:[]
        }
    },
    watch:{
    },
    computed:{
        bnts(){
            let data=["goBack","save"];
            return data;
        },

    },
    mounted(){
        if(this.$route.params?.id){
            this.loadData(this.$route.params.id);
        }
        let userInfo=this.$auth.getUserInfo(); //获取用户id
        /**订单渠道 */
        this.$cacheData.OrderChannel().then((d)=>{
            this.channelNav = d;
            let list=[]
            d?.map(order=>{
                order.OrderChannelList.forEach(item=>{
                    if(!this.channel_AutoID){
                        if(item.OrderChannel_AutoID==userInfo?.Default_ChannelID){//默认渠道
                            this.channel_Name = item.OrderChannel_Name;
                            this.channel_AutoID = item.OrderChannel_AutoID;
                        }
                    }else{
                        if(item.OrderChannel_AutoID==this.channel_AutoID){
                            this.channel_Name = item.OrderChannel_Name
                        }
                    }
                    item = Object.assign(item, {is_type:0,Kind_AutoID:order.OrderChannelKind_AutoID})
                    list.push(item)
                })
            })
            this.channelList=list;
        }).catch(()=>{
            this.$alert('暂无订单渠道', "提示", {confirmButtonText: "确定"});
        })

        /**销售员*/
        this.$cacheData.PosSellers().then((d)=>{
            this.salesList=d;
        }).catch(()=>{
            this.$alert('暂无销售员', "提示", {confirmButtonText: "确定"});
        })

        this.$cacheData.CstmLevel().then(d=>{
            this.cstmLevelList=d;
        }).catch(err=>{
            console.log("客户级别获取失败",err)
        })

        this.$cacheData.Relation().then(d=>{
            this.relationList=d;
            console.log(d)
        }).catch(err=>{
            console.log("关系类型获取失败",err)
        })    

        this.$cacheData.ComDay().then(d=>{
            this.comDayList=d;
            console.log(d)
        }).catch(err=>{
            console.log("纪念日类型获取失败",err)
        })    
    },
    methods:{
        back(){
            this.$router.push("customManage");
        },
        loadData(id){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetCstmInfo",{
                CstmAutoID:id
            }).then((d)=>{
                console.log(d)
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.info=d.ResponseBody||{};

                    let dietingList=[],likeList=[],diyList=[];
                    this.info.CstmInfoLabelList?.forEach(it=>{
                        if(it.LableType==0){
                            diyList.push(it);
                        }else if(it.LableType==1){
                            likeList.push(it);
                        }else if(it.LableType==2){
                            dietingList.push(it);
                        }
                    });
                    //忌口
                    this.dietingLableList=dietingList;
                    //喜好
                    this.likeLableList=likeList;
                    //自定义
                    this.diyLableList=diyList;

                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                    setTimeout(()=>{this.back()},500);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('未找到对应模板数据：'+e);
                console.log('未找到对应模板数据：'+e);
                setTimeout(()=>{this.back()},500);
            })
        },
        /**保存数据 */
        saveData(){
            let info= Object.assign({},this.info);

            if(!info.Cstm_Name){
                this.$message.warning('请输入名称！');
                return;
            }
            if(!(info.Cstm_Mobile)){
                this.$message.warning('请输入手机号！');
                return;
            }

            //标签
            info.CstmInfoLabelList=([]).concat(this.dietingLableList,this.likeLableList,this.diyLableList);
            
            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.SaveCstmInfo",{
                CstmInfo:info,
                Operator_Name:userInfo?.Login_Name,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("保存成功");
                    if(d.ResponseBody){
                        this.info.CstmAutoID=d.ResponseBody.CstmAutoID;
                    }
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('数据保存失败：'+e);
                console.log('数据保存失败：'+e);
            })
        },
        /**选择渠道 */
        orderChanneConfirm(item){
            this.info.Channel_ID = item.OrderChannel_AutoID;
            this.info.Channel_Name = item.OrderChannel_Name;
            this.orderChanneShow =false;
        },
        /**营销员返回 */
        waiSaleConfirm(seleUser){
            this.info.Eat_Sales = seleUser.User_Name;
            this.info.Eat_SalesID=seleUser.User_ID;
            this.waiSaleShow=false;
        },
        /**添加标签 */
        addLable(kind){
            if(kind==0){
                if(!this.divTag?.trim()){
                    this.$message.warning("请输入自定义内容");
                    return;
                }
                this.diyLableList.push({
                    LableType:0,
                    LableName:this.divTag.trim()
                });
                this.divTag="";
            }else if(kind==1){
                if(!this.likeTag?.trim()){
                    this.$message.warning("请输入喜好内容");
                    return;
                }
                this.likeLableList.push({
                    LableType:1,
                    LableName:this.likeTag.trim()
                });
                this.likeTag="";
            }else if(kind==2){
                if(!this.dietingTag?.trim()){
                    this.$message.warning("请输入忌口内容");
                    return;
                }
                this.dietingLableList.push({
                    LableType:2,
                    LableName:this.dietingTag.trim()
                });
                this.dietingTag="";
            }
        },
        /**移除标签 */
        removeLable(kind,index){
            if(kind==0){
                this.diyLableList.splice(index,1);
            }else if(kind==1){
                this.likeLableList.splice(index,1);
            }else if(kind==2){
                this.dietingLableList.splice(index,1);
            }
        },
        /**删除 纪念日*/
        deleteComDay(index){
            this.info.CstmInfoComDayList.splice(index,1);
        },
        /**添加 纪念日*/
        addComDay(){
            this.info.CstmInfoComDayList.push({
                ComDay: new Date(),
                ComDayType: false,
                Lose_ComYear: false,
                RelationName: "本人",
                RelationType: "生日",
            })
        }
    }
}
</script>

<style lang="scss">
@import './customManageEdit.scss'
</style>